<template>
	<div class="inside-page-content">
		<div class="paper">
			<div class="page">
				<div class="h1">云上科技城</div>
				<div class="notice-content" v-html="data.content" v-if="data.content"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'deviceDetail',
		components: {
			
		},
		data() {
			return {
				data: {}
			}
		},
		created() {
			this.$api.common.more().then((res) => {
				this.data = res.data.data.introduction;
			})
		},
		mounted() {

		},
		methods: {
			
		}
	}
</script>
<style scoped lang="less">
	.page {
		padding: 20px;
    min-width: 1100px;
    max-width: 1300px;
    margin: 0 auto;
		box-sizing: border-box;
		background: #fff;
		.h1 {
			font-weight: 700;
			text-align: center;
			border-bottom: 1px dashed #ddd;
			padding-bottom: 20px;
		}
		.notice-content {
			margin-top: 30px;
			line-height: 40px;
			display: flex;
			flex-direction: column;
			align-items: center;
		}
	}
</style>
